import { createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { dispatch } from 'redux_data/store';

const slice = createSlice({
  name: 'leave', // This is the name of the slice
  initialState: {
    hasError: null,
    isLoading: false,
    isLoadingDetail: false,
    datas: [],
    data: {},
    formValue:null,
  },
  reducers: {
    noError(state) { // NO ERROR
      state.hasError = false;
    },
    unsetError(state) { // UNSET ERROR
      state.hasError = null;
    },
    hasError(state, action) { // HAS ERROR
      state.isLoading = false;
      state.hasError = JSON.stringify(action.payload);
    },
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    startLoadingDetail: (state, action) => {
      state.isLoadingDetail = true;
    },
    setFormValue: (state, action) => {
      state.formValue = action.payload;
    },
    setDatas: (state, action) => {
      state.isLoading = false;
      state.datas = action.payload;
    },
    setData: (state, action) => {
      state.isLoadingDetail = false;
      state.data = action.payload;
    },
  },
});

export const { 
    noError , 
    unsetError , 
    hasError, 
    startLoading , 
    startLoadingDetail , 
    setFormValue ,
    setDatas , 
    setData ,
} = slice.actions;

const endpoint = "/leave"

export function searchData({ data = {} , params = {} } = {data: {} , params: {}}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      let endpointURL = `${endpoint}/search`;
      Object.keys(params).forEach(key => {
        if(endpointURL.includes("?")){
          endpointURL = `${endpointURL}&${key}=${params[key]}`;
        }else{
          endpointURL = `${endpointURL}?${key}=${params[key]}`;
        }
      })
      // console.log("data",data);
      const response = await axios.post( endpointURL , data )?.then( resp => resp?.data);
      // console.log("leave searchData", response);
      if(response?.success){
        dispatch(slice.actions.setDatas(response.data));
      }else{
        dispatch(slice.actions.hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(slice.actions.hasError(error.message));
      }else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function getDetailData({ data = {} , params = { "photo" : 1 } }) {
  return async (dispatch, getState) => {
    // console.log("detailData",data);
    dispatch(slice.actions.startLoadingDetail());
    try {
      let endpointURL = `${endpoint}/search`;
      Object.keys(params).forEach(key => {
        if(endpointURL.includes("?")){
          endpointURL = `${endpointURL}&${key}=${params[key]}`;
        }else{
          endpointURL = `${endpointURL}?${key}=${params[key]}`;
        }
      })
      const response = await axios.post( endpointURL , data )?.then( resp => resp?.data);
      // console.log("detailData", response);
      if(response?.success){
        dispatch(slice.actions.setData(response.data?.[0]));
      }else{
        dispatch(slice.actions.hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(slice.actions.hasError(error.message));
      }else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function addData({formData}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${endpoint}`, formData )?.then( resp => resp?.data);
      // console.log("addData", response);
      if(response?.success){
        dispatch(slice.actions.noError());
        dispatch(searchData());
      }else{
        dispatch(slice.actions.hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(slice.actions.hasError(error.message));
      }else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function editData({id, formData, encryptedPin}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      if(encryptedPin){ axios.defaults.headers.common.pin = encryptedPin; }
      const response = await axios.patch(`${endpoint}/${id}`, formData )?.then( resp => resp?.data);
      // console.log("editData", response);
      if(response?.success){
        dispatch(slice.actions.noError());
        dispatch(searchData());
      }else{
        dispatch(slice.actions.hasError(response));
      }
    } catch (error) {
      if(error?.message){
        dispatch(slice.actions.hasError(error.message));
      }else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

export function deleteData({list_id , encryptedPin}) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(list_id , encryptedPin);
      if(encryptedPin){ axios.defaults.headers.common.pin = encryptedPin; }
      const response = await axios.put(`${endpoint}/` , {id:list_id});
      if(response?.data?.success){
        dispatch(slice.actions.noError())
        dispatch(searchData());
      }else{
        dispatch(slice.actions.hasError(response?.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.response?.data));
    }
  };
}

export default slice.reducer;


// import { createSlice } from '@reduxjs/toolkit';
// import axios from '../../utils/axios';

// // ----------------------------------------------------------------------

// const initialState = {
//   isLoading: false,
//   isLoadingDetail: false,
//   hasError: null,
//   datas: [],
//   data: null,
//   sortBy: null,
//   formData: null, // form for load more
// };

// const slice = createSlice({
//   name: 'leave',
//   initialState,
//   reducers: {
//     // START LOADING
//     startLoading(state) {
//       state.isLoading = true;
//     },
//     startLoadingDetail(state) {
//       state.isLoadingDetail = true;
//     },

//     // NO ERROR
//     noError(state) {
//       state.hasError = false;
//     },

//     // UNSET ERROR
//     unsetError(state) {
//       state.hasError = null;
//     },

//     // HAS ERROR
//     hasError(state, action) {
//       state.isLoading = false;
//       state.hasError = JSON.stringify(action.payload);
//     },

//     // GET PRODUCTS
//     setDatas(state, action) {
//       state.isLoading = false;
//       state.datas = action.payload;
//       state.error = null;
//     },
//     appendDatas(state, action) {
//       state.isLoading = false;
//       // console.log(action.payload);
//       // console.log(state.datas);
//       state.datas.hasNextPage = action.payload.hasNextPage;
//       state.datas.page = action.payload.page;
//       state.datas.rows = state.datas.rows.concat(action.payload.rows);
//       state.error = null;
//     },

//     // GET PRODUCT
//     setData(state, action) {
//       state.isLoadingDetail = false;
//       // console.log(action.payload)
//       // if(Array.isArray(action.payload.leave_approvers)){
//       //   action.payload.leave_approvers = action.payload.leave_approvers.map((v,i) => v?.employee )
//       // }
//       // if(Array.isArray(action.payload.leave_members)){
//       //   action.payload.leave_members = action.payload.leave_members.map((v,i) => v?.employee )
//       // }
//       state.data = action.payload;
//       state.error = null;
//     },

//     setFormData(state, action) {
//       state.formData = action.payload;
//     },

//     //  SORT & FILTER PRODUCTS
//     sortByProducts(state, action) {
//       state.sortBy = action.payload;
//     },

//   },
// });

// // Reducer
// export default slice.reducer;

// // Actions
// // export const {
// //   getCart,
// //   addCart,
// //   resetCart,
// //   onGotoStep,
// //   onBackStep,
// //   onNextStep,
// //   deleteCart,
// //   createBilling,
// //   applyShipping,
// //   applyDiscount,
// //   increaseQuantity,
// //   decreaseQuantity,
// //   sortByProducts,
// //   filterProducts,
// // } = slice.actions;

// // ----------------------------------------------------------------------
// const emptyData = {
//   "count": 0,
//   "rows": [],
//   "page": 1,
//   "hasNextPage": false
// }

// export function unsetError() {
//   return async () => {
//     dispatch(slice.actions.unsetError());
//   }
// }

// // ----------------------------------------------------------------------

// export function getAll({queryParams} = {}) {
//   return async (dispatch, getState) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       let urlEndpoint = `/leave` // `/position?distinct=true`
//       if(queryParams){
//         Object.keys(queryParams).forEach((key)=>{
//           if(Array.isArray( queryParams[key] )){
//             queryParams[key].forEach((value)=>{
//               if(urlEndpoint.includes("?")){
//                 urlEndpoint += `&${key}=${value}`
//               }else{
//                 urlEndpoint += `?${key}=${value}`
//               }
//             })
//           }else if(urlEndpoint.includes("?")){
//             urlEndpoint += `&${key}=${queryParams[key]}`
//           }else{
//             urlEndpoint += `?${key}=${queryParams[key]}`
//           }
//         });
//       }
//       const response = await axios.get(urlEndpoint);
//       if(response?.data?.success){
//         dispatch(slice.actions.setDatas(response.data.data));
//         // if(page){
//         //   dispatch(slice.actions.appendDatas(response.data.data));
//         // }else{
//         //   dispatch(slice.actions.setDatas(response.data.data));
//         // }
//       }else{
//         dispatch(slice.actions.hasError(response?.data));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.response?.data));
//     }
//   };
// }

// // ----------------------------------------------------------------------

// export function getDetail(id) {
//   return async (dispatch, getState) => {
//     dispatch(slice.actions.startLoadingDetail());
//     try {
//       const response = await axios.get(`/leave?id=${id}&show_quota=1`);
//       // console.log(response?.data)
//       if(response?.data?.success){
//         dispatch(slice.actions.setData(response.data.data?.[0]));
//       }else{
//         dispatch(slice.actions.hasError(response?.data));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.response?.data));
//     }
//   };
// }

// // ----------------------------------------------------------------------

// export function addData(data) {
//   return async (dispatch, getState) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post(`/leave` , data);
//       if(response?.data?.success){
//         dispatch(slice.actions.noError())
//         dispatch(getAll())
//         // dispatch(slice.actions.setDatas(emptyData));
//       }else{
//         dispatch(slice.actions.hasError(response?.data));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.response?.data));
//     }
//   };
// }

// // ----------------------------------------------------------------------

// export function editData(id,data) {
//   return async (dispatch, getState) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       // [...data.entries()].forEach((row) => { console.log(row) })
      
//       const response = await axios.patch(`/leave/${id}` , data);
//       if(response?.data?.success){
//         dispatch(slice.actions.noError())
//         dispatch(getAll())
//         // dispatch(slice.actions.setDatas(emptyData));
//       }else{
//         dispatch(slice.actions.hasError(response?.data));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.response?.data));
//     }
//   };
// }

// // ----------------------------------------------------------------------

// export function deleteData({data}) {
//   return async (dispatch, getState) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.put(`/leave` , {ids:data});
//       // console.log(response?.data)
//       if(response?.data?.success){
//         dispatch(slice.actions.noError())
//         dispatch(getAll())
//         // dispatch(slice.actions.setDatas(emptyData));
//       }else{
//         dispatch(slice.actions.hasError(response?.data));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.response?.data));
//     }
//   };
// }

// export function selectOne(data) { 
//   return async (dispatch, getState) => {
//     // console.log(data)
//     dispatch(slice.actions.setData(data));
//   };
// }