import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import absentReducer from './slices/absent';
import presentReducer from './slices/present';
import adminReducer from './slices/admin';
import employeeReducer from './slices/employee';
import employee_positionReducer from './slices/employee_position';
import employee_statusReducer from './slices/employee_status';
import userReducer from './slices/user';
import companyReducer from './slices/company';
import storeReducer from './slices/store';
import teamReducer from './slices/team';
import time_markReducer from './slices/time_mark';
import reportReducer from './slices/report';
import worktimeReducer from './slices/worktime';
import approve_listReducer from './slices/approve_list';
import leaveReducer from './slices/leave';
// import expense_tagReducer from './slices/expense_tag';
// import incomeReducer from './slices/income';
// import income_categoryReducer from './slices/income_category';
// import purchase_orderReducer from './slices/purchase_order';
// import salesReducer from './slices/sales';

// ----------------------------------------------------------------------
const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  // whitelist: ["user"],
  whitelist: [],
};

const rootReducer = combineReducers({
  absent : absentReducer,
  present : presentReducer,
  admin : adminReducer,
  employee : employeeReducer,
  employee_position : employee_positionReducer,
  employee_status : employee_statusReducer,
  user : userReducer,
  company : companyReducer,
  store : storeReducer,
  team : teamReducer,
  time_mark : time_markReducer,
  report : reportReducer,
  worktime : worktimeReducer,
  approve_list : approve_listReducer,
  leave : leaveReducer,
  // expense_category : expense_categoryReducer,
  // expense_tag : expense_tagReducer,
  // income : incomeReducer,
  // income_category : income_categoryReducer,
  // purchase_order : purchase_orderReducer,
  // sales : salesReducer,
});
const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

const { dispatch } = store; 
const useSelector = useAppSelector;
const useDispatch = () => useAppDispatch();

export { store, persistor, useSelector, useDispatch };
// export { store, persistor, dispatch , useDispatch  };
