import { lazy,Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// import { Loadable } from 'routes/paths';
import LoadingScreen from 'components/LoadingScreen';
import useResponsive from 'utils/useResponsive';

export const Loadable = (Component) => (props) => {
    return (
      <Suspense fallback={<LoadingScreen/>}>
        <Component {...props} />
      </Suspense>
    );
  };

const NavBarHorizontal = Loadable(lazy(() => import('layout/NavBarHorizontal')));
const NavBarVertical = Loadable(lazy(() => import('layout/NavBarVertical')));
const MobileBackgroundLayout = Loadable(lazy(() => import('layout/MobileBackgroundLayout')));

export default function NavBarLayout() {
  const isDesktop = useResponsive('up', 'lg');
    return (
    <div> 
      {isDesktop ? (<div>
        <NavBarHorizontal />
        <Outlet />
      </div>) : 
      (<MobileBackgroundLayout />)}
    </div>
    )
}