import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Card } from '@mui/material';

// Define a border radius value
const radius = '75%';

const MobileBackground = () => {
  const theme = useTheme();

  return (
    <>
      {/* Background Layout */}
      <div
        style={{
          width: '100vw',
          height: '100vh',
          position: 'absolute',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            backgroundColor: theme.palette.primary.main,
            width: '200vw',
            height: window.screen.height * 0.6,
            borderBottomLeftRadius: radius,
            borderBottomRightRadius: radius,
            left: '-50%',
            position: 'absolute',
            top: -window.screen.height * 0.2,
          }}
        />
      </div>

      {/* Centering Container */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          paddingTop:"100px",
          paddingBottom:"100px",
          // width: '100vw',
          // height: '100vh',
          position: 'relative', // Relative to the whole viewport
          zIndex: 1, // Ensures content is above the background
          // overflow:"scroll"
        }}
      >
        {/* Card Component with Outlet */}
        <Card
          sx={{
            backgroundColor: 'white', // Ensure card content is visible
            boxShadow: 5, // Optional: adds shadow to the card
            padding: 0.5, // Adds padding inside the Card
            margin:0.5,
            // maxWidth: '400px', // Optional: Set a max width for the Card
            // width: '100%', // Full width up to the max width
          }}
        >
          <Outlet />
        </Card>
      </div>
    </>
  );
};

export default MobileBackground;
